import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    CheckInState,
    CheckInStrategy,
    PositionsSlotsUsersInfoId,
    UserPositionSlotUserInfoFragment
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { DurationService } from 'common/src/services/durationService';
import { IntervalService } from 'common/src/services/intervalService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { Button } from '../designSystem/components/button';
import { Icon } from '../designSystem/components/icon';
import { usePositionsSlotsUsersInfosCheckInMutation } from '../generated/graphqlHooks';
import { useHeavent } from '../hooks/useHeavent';
import { useMobileQuery } from '../hooks/useMobileQuery';
import { UserPositionDropdown } from './userPositionDropdown';

interface IUserPositionProps {
    canCheckIn: boolean;
    canDelete: boolean;
    positionSlotUserInfo: UserPositionSlotUserInfoFragment;

    onDelete(id: PositionsSlotsUsersInfoId): void;
    reloadCheckIn(id: PositionsSlotsUsersInfoId): void;
}

export const UserPosition = (props: IUserPositionProps) => {
    const { translate } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const durationService = useService(DurationService);
    const intervalService = useService(IntervalService);
    const { mutate: checkIn } = usePositionsSlotsUsersInfosCheckInMutation();
    const onMobile = useMobileQuery();
    const timeDifference = React.useMemo(() => {
        if (props.positionSlotUserInfo.checkInAt && props.positionSlotUserInfo.checkOutAt) {
            return durationService.minutesToHoursMinutesString(
                Math.round(
                    props.positionSlotUserInfo.checkOutAt
                        .diff(props.positionSlotUserInfo.checkInAt)
                        .shiftTo('minutes').minutes
                )
            );
        } else {
            return '';
        }
    }, [props.positionSlotUserInfo]);
    const subtitle = React.useMemo(() => {
        const ps = props.positionSlotUserInfo.positionSlot;

        if (isNonEmptyString(ps.name)) {
            return `${ps.name} - ${intervalService.toDisplayString(ps.range)}`;
        } else {
            return intervalService.toDisplayString(ps.range);
        }
    }, [props.positionSlotUserInfo.positionSlot]);

    return (
        <Flex
            css={{
                background: 'white',
                border: '1px solid $gray200',
                borderRadius: '$1',
                boxShadow: '$xs',
                '@tablet': {
                    borderRadius: '$2'
                }
            }}
            direction="column"
            width={1}
        >
            <Flex
                align="center"
                css={{
                    padding: '$3',
                    '@tablet': {
                        padding: '$3 $4'
                    }
                }}
                gap="3"
                to={
                    props.canCheckIn
                        ? PositionsPaths.POSITION({
                              organizationId: props.positionSlotUserInfo.organizationId,
                              eventId: props.positionSlotUserInfo.eventId,
                              positionId: props.positionSlotUserInfo.position.id
                          })
                        : undefined
                }
                width={1}
                wrap="wrap"
            >
                <Icon
                    color={props.positionSlotUserInfo.position.color}
                    icon={props.positionSlotUserInfo.position.icon}
                />

                <Flex css={{ flex: '1', overflow: 'hidden' }} direction="column" gap="1">
                    <Box
                        color="gray800"
                        css={{
                            fontWeight: '$medium',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            '@tablet': { fontWeight: '$semiBold' }
                        }}
                    >
                        {props.positionSlotUserInfo.position.name}
                    </Box>

                    <Box color="gray500" fontSize="textSm">
                        {subtitle}
                    </Box>
                </Flex>
            </Flex>

            {(props.canCheckIn || props.canDelete) && (
                <Flex
                    align="center"
                    css={{
                        borderTop: '1px solid $gray200',
                        padding: '$3',
                        '@tablet': { padding: '$3 $4' }
                    }}
                    justify="between"
                    width={1}
                >
                    {props.positionSlotUserInfo.state === CheckInState.CheckedOut ? (
                        <Badge leftIcon="clock">
                            {translate('temps_total_79448', timeDifference)}
                        </Badge>
                    ) : props.positionSlotUserInfo.state === CheckInState.CheckedIn ? (
                        <Badge color="success" leftIcon="circle" leftIconStyle="solid">
                            {translate(
                                'point_le_1_63576',
                                dateTimeService.toLocaleString(
                                    props.positionSlotUserInfo.checkInAt!.toLocal(),
                                    onMobile ? LocaleFormats.ShortDateTime : LocaleFormats.DateTime
                                )
                            )}
                        </Badge>
                    ) : (
                        <Badge color="warning" leftIcon="circle" leftIconStyle="solid">
                            {translate('non_point_42145')}
                        </Badge>
                    )}

                    <Flex gap="3">
                        {props.canCheckIn &&
                            props.positionSlotUserInfo.state === CheckInState.Waiting && (
                                <Button
                                    size="sm"
                                    onClick={async () => {
                                        await checkIn({
                                            eventId: props.positionSlotUserInfo.eventId,
                                            checkIn: {
                                                strategy: CheckInStrategy.CheckIn,
                                                positionsSlotsUsersInfosIds: [
                                                    props.positionSlotUserInfo.id
                                                ]
                                            }
                                        });

                                        props.reloadCheckIn(props.positionSlotUserInfo.id);
                                    }}
                                >
                                    {translate('pointer_80927')}
                                </Button>
                            )}

                        {props.canCheckIn &&
                            props.positionSlotUserInfo.state === CheckInState.CheckedIn && (
                                <Button
                                    color="white"
                                    size="sm"
                                    onClick={async () => {
                                        await checkIn({
                                            eventId: props.positionSlotUserInfo.eventId,
                                            checkIn: {
                                                strategy: CheckInStrategy.CheckOut,
                                                positionsSlotsUsersInfosIds: [
                                                    props.positionSlotUserInfo.id
                                                ]
                                            }
                                        });

                                        props.reloadCheckIn(props.positionSlotUserInfo.id);
                                    }}
                                >
                                    {translate('d_pointer_25328')}
                                </Button>
                            )}

                        <UserPositionDropdown
                            canCheckIn={props.canCheckIn}
                            canDelete={props.canDelete}
                            psui={props.positionSlotUserInfo}
                            reloadCheckIn={props.reloadCheckIn}
                            triggerElem={
                                <Box>
                                    <Button color="white" rightIcon="ellipsis-vertical" size="sm" />
                                </Box>
                            }
                            onDelete={props.onDelete}
                        />
                    </Flex>
                </Flex>
            )}
        </Flex>
    );
};
