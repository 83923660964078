import { StyledComponent } from '@stitches/react/types/styled-component';
import { omit, pick } from 'lodash-es';
import * as React from 'react';
import { convertToPercentOrPx } from '../util/unit';
import { ILinkProps, Link } from './link';
import { CSS, ResponsiveProperty, styled } from './stitches';

export enum SectioningElement {
    Nav = 'nav',
    Header = 'header',
    Main = 'main',
    Section = 'section',
    Aside = 'aside',
    Article = 'article'
}

export function pickBaseFlexProps<T extends BaseFlexProps>(props: T): any {
    return pick(props, ['direction', 'align', 'justify', 'wrap', 'gap', 'css', 'height', 'width']);
}

const style = {
    display: 'flex',
    variants: {
        direction: {
            row: {
                flexDirection: 'row'
            },
            column: {
                flexDirection: 'column'
            },
            rowReverse: {
                flexDirection: 'row-reverse'
            },
            columnReverse: {
                flexDirection: 'column-reverse'
            }
        },
        align: {
            start: {
                alignItems: 'flex-start'
            },
            center: {
                alignItems: 'center'
            },
            end: {
                alignItems: 'flex-end'
            },
            stretch: {
                alignItems: 'stretch'
            },
            baseline: {
                alignItems: 'baseline'
            }
        },
        justify: {
            start: {
                justifyContent: 'flex-start'
            },
            center: {
                justifyContent: 'center'
            },
            end: {
                justifyContent: 'flex-end'
            },
            between: {
                justifyContent: 'space-between'
            },
            evenly: {
                justifyContent: 'space-evenly'
            }
        },
        wrap: {
            noWrap: {
                flexWrap: 'nowrap'
            },
            wrap: {
                flexWrap: 'wrap'
            },
            wrapReverse: {
                flexWrap: 'wrap-reverse'
            }
        },
        gap: {
            1: {
                gap: '$1'
            },
            2: {
                gap: '$2'
            },
            3: {
                gap: '$3'
            },
            4: {
                gap: '$4'
            },
            5: {
                gap: '$5'
            },
            6: {
                gap: '$6'
            },
            7: {
                gap: '$7'
            },
            8: {
                gap: '$8'
            },
            9: {
                gap: '$9'
            },
            10: {
                gap: '$10'
            },
            11: {
                gap: '$11'
            },
            12: {
                gap: '$12'
            },
            13: {
                gap: '$13'
            }
        }
    },
    defaultVariants: {
        direction: 'row',
        align: 'stretch',
        justify: 'start',
        wrap: 'noWrap'
    }
};

export type Gap = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13';

export interface BaseFlexProps {
    direction?: ResponsiveProperty<'row' | 'column' | 'rowReverse' | 'columnReverse'>;
    align?: ResponsiveProperty<'start' | 'center' | 'end' | 'stretch' | 'baseline'>;
    justify?: ResponsiveProperty<'start' | 'center' | 'end' | 'between' | 'evenly'>;
    wrap?: ResponsiveProperty<'noWrap' | 'wrap' | 'wrapReverse'>;
    gap?: ResponsiveProperty<Gap>;
    css?: CSS;
    height?: number | string;
    width?: number | string;
}

export type IFlexProps = {
    className?: string;
    children?: React.ReactNode;
    onClick?: React.MouseEventHandler<any>;
} & Omit<BaseFlexProps, 'wrap'> &
    Omit<React.HTMLProps<HTMLDivElement>, 'onClick'> &
    Partial<Omit<ILinkProps, 'onClick'>>;

export const Flex = React.forwardRef(
    (
        { as, disabled, onClick, target, replace, to, ...props }: IFlexProps,
        ref: React.Ref<HTMLDivElement>
    ) => {
        const css: CSS = {};
        const _Flex = React.useMemo(() => styled((as ?? 'div') as any, style), [as]);

        const renderElement = () => (
            <_Flex
                // TODO - properly filter out <Link /> props
                {...(props as StyledComponent<HTMLDivElement>)}
                ref={ref}
                css={{
                    ...css,
                    ...props.css
                }}
                onClick={onClick as React.MouseEventHandler<HTMLDivElement>}
            />
        );

        if (props.height) {
            css.height = convertToPercentOrPx(props.height);
        }

        if (props.width) {
            css.width = convertToPercentOrPx(props.width);
        }

        return to && !disabled ? (
            <Link
                height={props.height}
                replace={replace}
                to={to}
                onClick={onClick as React.MouseEventHandler<HTMLAnchorElement>}
                {...(omit(props, 'align') as Omit<ILinkProps, 'to' | 'onClick'>)}
            >
                {renderElement()}
            </Link>
        ) : (
            renderElement()
        );
    }
);
Flex.displayName = 'Flex';
