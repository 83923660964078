import { Flex } from 'common/src/designSystem/components/flex';
import { CSS } from 'common/src/designSystem/components/stitches';
import { preventDefault } from 'common/src/util/links';
import { noop } from 'lodash-es';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { useDropdownContext } from './dropdown';

interface IMenuProps {
    children: React.ReactNode;
    css?: CSS;
    'aria-orientation'?: 'horizontal' | 'vertical';
    id?: string;
    maxHeight?: number;
    offset?: number;
    placement: 'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end';
    shouldPreventDefault?: boolean;
    renderOnPortal?: boolean;
    width?: 'match' | number;
}

export const Menu = (props: IMenuProps) => {
    const { isOpen, referenceElement, popperElement, setPopperElement } = useDropdownContext();
    const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
        placement: props.placement,
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, props.offset ?? 8]
                }
            }
        ]
    });
    const width =
        props.width === 'match'
            ? `${referenceElement?.getBoundingClientRect().width ?? 240}px`
            : typeof props.width === 'number'
              ? `${props.width}px`
              : 'fit-content';

    React.useEffect(() => {
        update?.();
    }, [update, isOpen, referenceElement]);

    React.useEffect(() => {
        if (referenceElement) {
            const observer = new ResizeObserver(() => {
                update?.();
            });

            observer.observe(referenceElement);

            return () => {
                observer.disconnect();
            };
        } else {
            return noop;
        }
    }, [update, referenceElement]);

    const menu = (
        <Flex
            ref={setPopperElement}
            aria-hidden={!isOpen}
            aria-orientation={props['aria-orientation']}
            css={{
                background: 'white',
                border: '1px solid $gray200',
                borderRadius: '$1',
                boxShadow: '$lg',
                display: isOpen ? 'flex' : 'none',
                maxHeight: props.maxHeight ? `${props.maxHeight}px` : undefined,
                overflowX: 'hidden',
                overflowY: 'auto',
                py: '$2',
                width,
                zIndex: 1000,
                ...(props.css as any),
                ...styles.popper,
                '&:empty': {
                    opacity: 0
                }
            }}
            direction="column"
            id={props.id}
            role="menu"
            onClick={(e) => preventDefault(e, props.shouldPreventDefault ?? false)}
            {...attributes.popper}
            tabIndex={-1}
        >
            {props.children}
        </Flex>
    );

    return isOpen ? (props.renderOnPortal ? createPortal(menu, document.body) : menu) : null;
};
